<template>
  <div>
    <!-- 面包屑 -->
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>公司管理</el-breadcrumb-item>
      <el-breadcrumb-item>公司列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 卡片区 -->
    <el-card>
      <!-- 搜索 按钮 区域 -->
      <el-row>
        <el-col :span="7">
          <el-input placeholder="请输入公司名称" v-model="queryInfo.query" @keyup.enter="getCompanyList()" clearable @clear="getCompanyList()">
            <template #append><el-button icon="el-icon-search" @click="getCompanyList()"></el-button></template>
          </el-input>
        </el-col>
        <el-col :span="14"></el-col>
        <el-col :span="3">
          <el-button style="width:100%;padding:1px" type="primary" icon="el-icon-plus" @click="addDialogVisible=true">添加</el-button>
        </el-col>
      </el-row>
      <!-- 列表区域 -->
      <el-table :data="companyList" stripe border style="width: 100%">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column prop="companyName" label="公司名称"></el-table-column>
        <el-table-column prop="adminPhone" label="管理员账号"></el-table-column>
        <el-table-column label="当前状态">
          <template v-slot="scope">
            <span>{{scope.row.companyStatus?'已注销':'使用中'}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template v-slot="scope">
            <el-button size="mini" type="danger" icon="el-icon-delete" :disabled="scope.row.companyStatus==1" @click="cancelCompany(scope.row)" >注销</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 分页 区域 -->
      <el-config-provider :locale="locale">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pagenum"
          :page-sizes="[1, 2, 5, 10]"
          :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </el-config-provider>
    </el-card>

    <!-- 添加公司 对话框 -->
    <el-dialog v-model="addDialogVisible" title="添加公司" width="50%" @closed="closedAddForm()">
      <!-- 内容主体  添加表单 -->
      <el-form :model="addForm" :rules="rules" ref="addForm" label-width="100px">
        <el-form-item label="公司名称" prop="companyName">
          <el-input v-model="addForm.companyName"></el-input>
        </el-form-item>
        <el-form-item label="新管理员名字" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="新管理员手机号" prop="phone">
          <el-input v-model="addForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="addForm.password" type="password" autocomplete="off" show-password ></el-input>
        </el-form-item>
      </el-form>
      <!-- 底部 -->
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialogVisible = false">取消</el-button>
          <el-button type="primary" @click="addCompany('addForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {
  data() {
    const validatePhone = (rule, value, callback) =>{
      const reg =/^[1][3,4,5,7,8][0-9]{9}$/;
      if (value === "") {
        callback(new Error("请输入手机号码"));
      } else {
        if (!reg.test(value) && value !== "") {
          callback(new Error("请输入正确的手机号码"));          
        }else{
          callback();
        }
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        callback();
      }
    };
    const validateName = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入公司名称"));
      } else {
        callback();
      }
    };
    const validatename = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新管理员名称"));
      } else {
        callback();
      }
    };
    return {
      // 列表的请求 参数对象
      queryInfo: {
        query: "",
        pagenum: 1, //页数
        pagesize: 5 //条数
      },
      // 响应 参数对象
      companyList: [],
      total: 0,
      addDialogVisible: false,
      addForm: {
        companyName:"",
        name:"",
        phone:"",
        password:""
      },
      rules: {
        companyName: [{ validator: validateName, trigger: "blur" }],
        name: [{ validator: validatename, trigger: "blur" }],
        phone: [{ validator: validatePhone, trigger: "blur" }],
        password: [{ validator: validatePass, trigger: 'blur' }]
      }
    };
  },
  setup() {
    return {
      locale: zhCn
    };
  },
  mounted() {
    this.getCompanyList();
  },
  methods: {
    async getCompanyList() {
      const { data: res } = await this.$http.get("company/getCompanyList", { params: this.queryInfo });
      if (res.code !== 200)
        return this.$message.error(res.msg);
      this.companyList = res.data.list;
      this.total = res.data.total;
    },
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize;
      this.getCompanyList();
    },
    handleCurrentChange(newPage) {
      this.queryInfo.pagenum = newPage;
      this.getCompanyList();
    },
    closedAddForm(){
      this.$refs.addForm.resetFields()
    },
    addCompany(form) {
      this.$refs[form].validate(async valid => {
        if (!valid) { return;}
        this.addDialogVisible = false;
        let formData = new FormData();
        formData.append('companyName',this.addForm.companyName)
        formData.append('adminName',this.addForm.name)
        formData.append('adminPhone',this.addForm.phone)
        formData.append('password',this.$md5(this.addForm.password))
        const { data: res } = await this.$http.post("company/addCompany",formData);
        console.log(res);
        if (res.code !== 200){ return this.$message.error(res.msg);}
        this.$message.success("公司注册成功！");
        this.getCompanyList();
      });
    },
    async cancelCompany(company){
      const confirmRes = await this.$confirm("确认注销该公司？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).catch(err => console.log(err));
      if (confirmRes !== "confirm") { return this.$message.info("取消注销");}
      let formData = new FormData();
      formData.append('id',company.companyId);
      const { data: res } = await this.$http.post('company/cancelCompany',formData)
      if (res.code !== 200){return this.$message.error(res.msg);}
      this.$message.success(res.msg);
      this.getCompanyList();
    },
  }
};
</script>

<style lang="less" scoped>

</style>
